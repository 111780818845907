<template>
  <div class="corner-module">
    <div class="site-name">
      OMG Tech
    </div>
    <div class="footer-msg-row">
      Nikmati berita yang kami sajikan untuk anda semua, cermati dan bijaklah dalam menyampaikan
      kembali berita.
    </div>
  </div>
</template>

<script>
import '@/css/module/foot-theme-1.scss';

export default {
  name: 'Footcollection',
};
</script>
